import React, { Fragment, useEffect, useState, useCallback } from 'react';
import {
  Col,
  Card,
  CardHeader,
  Table,
  Row,
  Container,
  CardBody,
} from 'reactstrap';
import { Clients } from '../../../Constant';
import { H5, Btn } from '../../../AbstractElements';
import ModalButton from '../../Common/Data/Ui-kits/Modals/VaryingContent/common/ModalButton';
import { Link, useLocation } from 'react-router-dom';
//import TablePagination from 'CommonElements/Table/TableCustomerPagination';
import TablePagination from 'CommonElements/Table/TablePagination';
import AssignModal from './Modal/assignTo';
import { clientService, activitiesService } from 'Services';
import { useSelector } from 'react-redux';
import { getMenuPerm } from 'utils';
import DataTable from 'react-data-table-component';
import TableLoader from "CommonElements/Table/TableLoader";
import TableRowStatus from "CommonElements/Table/TableRowStatus";
import { writeFile, utils } from 'xlsx';
import Filter from '../../Common/Component/filter';
import Select from 'react-select';
import { FSwitch } from "CommonElements/Form";
import { toast } from "react-toastify";

const useQuery = () => {
  return new URLSearchParams(useLocation().search);
}

const HoverableRowsClass = () => {
  const [rec, setRec] = useState({});
  const [modal, setModal] = useState(false);
  const [clientBulkModal, setClientBulkModal] = useState(false);
  const [selectedRows, setSelectedRows] = useState([]);
  const [toggleCleared, setToggleCleared] = useState(false);
  const [modalMessage, setmodalMessage] = useState('');
  const [searchValue, setSearchValue] = useState({});

  const groupId = useSelector((state) => state.login?.profile?.userGroups);
  //const roles = useSelector((state) => state.login?.profile?.Role);
  const [limit, setLimit] = useState(25);
  const [initialPage, setInitialPage] = useState(1);

  const options = [
    { value: '25', label: '25' },
    { value: '50', label: '50' },
    { value: '100', label: '100' },
  ]
  console.log('groupId: ', groupId);
  const query = useQuery();

  // Function to handle limit change
  const handleLimitChange = (selectedOption) => {
    const newLimit = selectedOption.value; // Use the selected option's value directly
    setLimit(newLimit); // Update the limit state
    //loadData(groupId); // Pass the array of groupIds to loadData

    appendParameterToUrl('limit', newLimit);
  };

  const loadData = (groupId) => {
    const currentUrl = window.location.href;
    let { page, limit, bodyParams } = getParamsAndBody(currentUrl);
    setInitialPage(page);

    bodyParams.isClient = true;
    bodyParams.groupId = groupId;


    //roles && roles.country && (bodyParams.country = 'Morocco');
    (!bodyParams.page || !bodyParams.limit) &&
      setRec({
        loading: true,
      })
    clientService
      .searchCustomers({
        page,
        limit,
        ...bodyParams,
      })
      .then((res) => {
        if (res.data.length > 0) {
          res.data = res.data.map((item) => ({
            ...item,
            isEmailView: false,
            isPhoneView: false,
          }));
        }
        console.log('res: ', res);
        setRec({
          ...res,
          loading: false,
        });
      })
      .catch((err) => {
        // Handle errors
        console.log('err: ', err);
      });
  };


  const appendParameterToUrl = (key, value) => {
    // Get the current URL
    const currentUrl = window.location.href;

    // Create a URL object
    const url = new URL(currentUrl);

    if (key == 'subLeads') {
      url.search = '';
    }
    if (key == 'callStatus' || key == 'limit' || key == 'dateFrom' || key == 'dateFrom' || key == 'agentId' ||
      key == 'firstName' || key == 'entity' || key == 'kycStatus' || key == 'isFunded' ||
      key == 'approvalStage' || key == 'accountStatus' || key == 'parentId' || key == 'firstName') {
      url.searchParams.set('page', 1);
    }

    // Check if the parameter exists
    if (url.searchParams.has(key)) {
      // Update the existing parameter
      url.searchParams.set(key, value);
    } else {
      // Append the new parameter
      url.searchParams.append(key, value);
    }

    if ((key == 'entity' && value == '') || (key == 'isFunded' && value == '') || (key == 'firstName' && value == '') ||
      (key == 'approvalStage' && value == '') || (key == 'accountStatus' && value == '') || (key == 'parentId' && value == '')) {
      console.log("value is ", value);
      url.searchParams.delete(key);
    }
    // Update the browser's address bar
    window.history.pushState(null, '', url.toString());
    loadData(groupId);
  };

  // Function to separate page and limit, and construct body params
  const getParamsAndBody = (url) => {
    const queryParams = extractUrlParams(url);

    const page = queryParams.page || 1;
    const limit = queryParams.limit || 25;
    setLimit(limit);
    // Remove page and limit from queryParams to construct the body parameters
    delete queryParams.page;
    delete queryParams.limit;

    const bodyParams = queryParams;

    return {
      page,
      limit,
      bodyParams
    };
  };

  // Function to extract query parameters from the URL
  const extractUrlParams = (url) => {
    const urlObj = new URL(url);
    const params = new URLSearchParams(urlObj.search);
    const queryParams = {};
    for (const [key, value] of params) {
      queryParams[key] = value;
    }

    return queryParams;
  };

  const handleSubLeads = () => {
    appendParameterToUrl('subLeads', 'true');
  }

  useEffect(() => {
    if (groupId || searchValue) {
      loadData(groupId); // Pass the array of groupIds to loadData
    }
  }, [groupId, searchValue]);

  const permissions = useSelector(
    (state) =>
      (state.login &&
        state.login.profile &&
        state.login.profile.Role &&
        state.login.profile.Role.permissions) ||
      {}
  );

  const user = useSelector(
    (state) =>
      (state.login &&
        state.login.profile) ||
      {}
  );

  const emailPhoneViewClick = (e, index, customerId, type) => {
    e.preventDefault();
    var updatedRecord = rec;
    var isActive = false;
    var activityType = '';
    if (type === 'emailView') {
      activityType = 'email';
      isActive = updatedRecord.data[index].isEmailView === true ? false : true;
      updatedRecord.data[index].isEmailView = updatedRecord.data[
        index
      ].isEmailView = isActive;
    } else if (type === 'phoneView') {
      activityType = 'phone';
      isActive = updatedRecord.data[index].isPhoneView === true ? false : true;
      updatedRecord.data[index].isPhoneView = updatedRecord.data[
        index
      ].isPhoneView = isActive;
    }
    if (isActive) {
      var postData = {
        type: activityType,
        customerId: customerId,
        userId: user.id,
        note: {'note': activityType + ' View '},
      };
      postActivities(postData);
    }
    setRec({
      ...updatedRecord,
      loading: false,
    });
  };
  const postActivities = (payload) => {
    activitiesService
      .postActivity(payload)
      .then((res) => { })
      .catch((err) => {
      });
  };
  const clearSelectedRow = () => {
    setToggleCleared(!toggleCleared);
    setSelectedRows([]);
  };
  const tableColumns = [
    {
      name: 'Name',
      sortable: true,
      center: true,
      cell: (item) => (
        <Link className=''
          to={{
            pathname: `/clients/${item.id}`,
          }}>{`${item.firstName} ${item.lastName ? item.lastName : ''}`}</Link>
      ),
    },
    ...(getMenuPerm(permissions, 'clientDetails:email') === 1 ? [{
      name: 'Email',
      sortable: true,
      center: true,
      cell: (item, index) => (
        <div>
          {!item.isEmailView ? (
            <span>
              ******
              <i
                className='fa fa-eye-slash'
                style={{
                  position: 'absolute',
                  cursor: 'pointer',
                  marginLeft: '10px',
                }}
                onClick={(e) =>
                  emailPhoneViewClick(e, index, item.id, 'emailView')
                }
              ></i>
            </span>
          ) : (
            <span>
              {item.email}
              <i
                className='fa fa-eye'
                style={{
                  position: 'absolute',
                  cursor: 'pointer',
                  marginLeft: '10px',
                }}
                onClick={(e) =>
                  emailPhoneViewClick(e, index, item.id, 'emailView')
                }
              ></i>
            </span>
          )}
        </div>
      ),
    }] : []),
    {
      name: 'Registration',
      selector: (row) => new Date(row.createdAt).toLocaleString(),
      sortable: true,
      center: true,
    },
    {
      name: 'Update Time',
      selector: (row) => new Date(row.updatedAt).toLocaleString(),
      sortable: true,
      center: true,
    },
    {
      name: 'Sales Person',
      // selector: (row) => row.country,
      sortable: true,
      center: true,
      cell: (item, index) => (
        <div>
          {item['Agent.firstName']} {item['Agent.lastName']}
        </div>
      ),
    },
    {
      name: 'Country',
      selector: (row) => row.country,
      sortable: true,
      center: true,
    },
    {
      name: 'Status',
      selector: (row) => row.accountStatus || 'Demo',
      sortable: true,
      center: true,
      cell: (row) => <TableRowStatus status={row.accountStatus} />,
    },
    {
      name: 'IB',
      selector: (row) => {
        const firstName = row['Parent.firstName'] || '';
        const lastName = row['Parent.lastName'] || '';
        const fullName = `${firstName} ${lastName}`.trim();

        return fullName ? fullName : 'Direct Client';
      },
      sortable: true,
      center: true,
    },
    {
      name: 'Approval Stage',
      selector: (row) => (row.approvalStage || 'New Application'),
      sortable: true,
      center: true,
    },
    // {
    //   name: 'Active Status',
    //   selector: (row) => row.isActive, // This is optional if you want to sort or filter by this column
    //   center: true,
    //   cell: (row) => (
    //     getMenuPerm(permissions, 'clients:update') === 1 && (
    //       <FSwitch
    //         onChange={() => changeAccess(row, row.id)}
    //         value={row.isActive}
    //       />
    //     )
    //   ),
    // },

  ];
  const handleRowSelected = useCallback((state) => {
    setSelectedRows(state.selectedRows);
  }, []);
  const toggle = (e) => {
    if (e.target.textContent == 'Sales Assign') {
      setmodalMessage('Assign to');
    } else if (e.target.textContent == 'Delete Clients') {
      setmodalMessage('Delete Clients');
    }
    setModal(!modal);
  };
  const clientUploadToggle = (e) => {
    setClientBulkModal(!clientBulkModal);
  };
  function exportToExcel(data) {
    const worksheet = utils.json_to_sheet(data);
    const workbook = utils.book_new();
    utils.book_append_sheet(workbook, worksheet, 'client_sheet');
    return workbook;
  }
  const handleExport = () => {
    const workbook = exportToExcel(rec.data);
    writeFile(workbook, 'client_list.xlsx');

    var postData = {
      type: 'Client Data Export',
      customerId: user.id,
      userId: user.id,
      note: {'note': 'client_list.xlsx'},
    };
    postActivities(postData);

  };

  const changeAccess = (item, index) => {
    clientService
      .updateClientAccess(
        {
          isActive: !item.isActive,
        },
        item.id
      )
      .then((res) => {
        rec.data[index].isActive = !item.isActive;
        toast.success("Client updated, successfully");
      })
      .catch((err) => {
        toast.error("Failed to update Client");
      });
  };


  return (
    <Fragment>
      {rec.loading && <TableLoader />}
      {!rec.loading && (
        <Col sm='12'>
          <Card>
            <CardHeader>
              <Row>
                <Col md='4'>
                  <H5>{Clients}</H5>
                </Col>
                <Col md='8'>
                  <Row>
                    {selectedRows.length > 0 ? (
                      <Col md='2'>
                        <Btn attrBtn={{ color: 'primary', onClick: toggle }}>
                          Sales Assign
                        </Btn>
                        <AssignModal
                          modal={modal}
                          toggle={toggle}
                          setModal={setModal}
                          selectedSales={selectedRows}
                          checkBoxClear={clearSelectedRow}
                          loadClientData={loadData}
                          titleMessage={modalMessage}
                        />
                      </Col>
                    ) : (
                      <Col md='3'></Col>
                    )}
                    {getMenuPerm(permissions, 'clients:delete') === 1 && selectedRows.length > 0 && (
                      <Col md='2'>
                        <Btn
                          attrBtn={{
                            color: 'secondary',
                            onClick: toggle,
                          }}
                        >
                          Delete Clients
                        </Btn>
                      </Col>
                    )}
                    {/* <Col md='2'>
                    <Btn
                      attrBtn={{
                        color: 'btn btn-outline-primary ms-2',
                        onClick: clientUploadToggle,
                      }}
                    >
                      <i
                        className='fa fa-upload'
                        style={{ padding: '3px' }}
                      ></i>
                      <ClientModal
                        modal={clientBulkModal}
                        NewMessage='Upload Clients'
                        toggle={clientUploadToggle}
                        setModal={setClientBulkModal}
                      />
                      Upload
                    </Btn>
                  </Col> */}
                    {getMenuPerm(permissions, 'clients:export') === 1 && rec.data && (
                      <Col md='1'>
                        <Btn
                          attrBtn={{
                            color: 'success',
                            onClick: handleExport,
                          }}
                        >
                          Export
                        </Btn>
                      </Col>
                    )}
                    <Col md={'1'}>
                      {getMenuPerm(permissions, 'clients:create') === 1 && (
                        <ModalButton btnText='Add' defaultVal='Godo' loadData={loadData} />
                      )}
                    </Col>

                    <Col md="2">
                      <Select
                        defaultValue={options.find(option => option.value === limit.toString())}
                        onChange={handleLimitChange}
                        options={options}
                      />
                    </Col>
                    { user && user.userGroups?.length > 0 && <Col md='2'>
                      <Btn
                        attrBtn={{
                          color: 'success',
                          onClick: handleSubLeads,
                        }}
                      >
                        Sub Clients
                      </Btn>
                    </Col>}
                  </Row>
                </Col>
              </Row>
            </CardHeader>
            <div className='table-responsive'>
              <Container fluid={true} className='datatables'>
                <Row>
                  <Col sm='12'>
                    <Card>
                      {rec.data ? (
                        <CardBody>
                          <Filter filter={appendParameterToUrl} user={user} show={'isClient'} />
                          <DataTable
                            data={rec.data}
                            columns={tableColumns}
                            striped={true}
                            center={true}
                            selectableRows
                            persistTableHead
                            onSelectedRowsChange={handleRowSelected}
                            clearSelectedRows={toggleCleared}
                          />
                        </CardBody>
                      ) : null}
                    </Card>
                  </Col>
                </Row>
              </Container>
              <TablePagination data={rec} loadData={loadData} limit={limit} param={appendParameterToUrl} />
            </div>
          </Card>
        </Col>
      )}
    </Fragment>
  );
};

export default HoverableRowsClass;
