import api from './api';

export const BonusReport = () => {
    return api.get('/v1/crm/BonusReport/');
};

export const createBonusReport = (data) => {
  return api.post('/v1/crm/BonusReport/', data);
};

export const deleteBonusReport = (id) => {
  return api.delete(`/v1/crm/BonusReport/${id}`);
};

export const updateBonusReport = (id, data) => {
  return api.put(`/v1/crm/BonusReport/${id}`, data);
};


