import api from './api';

export const BonusAccount = () => {
    return api.get('/v1/crm/BonusAccount/');
};

export const createBonusAccount = (data) => {
  return api.post('/v1/crm/BonusAccount/', data);
};

export const deleteBonusAccount = (id) => {
  return api.delete(`/v1/crm/BonusAccount/${id}`);
};

export const updateBonusAccount = (id, data) => {
  return api.put(`/v1/crm/BonusAccount/${id}`, data);
};


