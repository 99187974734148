import api from './api';

export const fetchfinreq = () => {
    return api.get(`/v1/crm/finreq`);
};

export const createfinreq = (data) => {
    return api.post(`/v1/crm/finreq`, data);
};

export const updatefinreq= (id, data) => {
    return api.put(`/v1/crm/finreq/${id}`, data);
};

export const deletefinreq = (id) => {
    return api.delete(`/v1/crm/finreq/${id}`);
};
    