import api from './api';

export const fetchEmplocation = () => {
    return api.get(`/v1/crm/employeegeolocation`);
};

export const createEmplocation = (data) => {
    return api.post(`/v1/crm/employeegeolocation`, data);
};

export const updateEmplocation= (id, data) => {
    return api.put(`/v1/crm/employeegeolocation/${id}`, data);
};

export const deleteEmplocation = (id) => {
    return api.delete(`/v1/crm/employeegeolocation/${id}`);
};
    