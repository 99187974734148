import api from './api';

export const fetchtasks = () => {
    return api.get(`/v1/crm/tasks`);
};

export const createtasks = (data) => {
    return api.post(`/v1/crm/tasks`, data);
};

export const updatetasks= (id, data) => {
    return api.put(`/v1/crm/tasks/${id}`, data);
};

export const deletetasks = (id) => {
    return api.delete(`/v1/crm/tasks/${id}`);
};
    