import api from './api';

export const BonusSync = () => {
    return api.get('/v1/crm/BonusSync/');
};

export const updateBonusSync = (id, updatedData) => {
  return api.put(`/v1/crm/BonusSync/${id}`, updatedData);
};


