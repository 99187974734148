import React, { Fragment, useState, useEffect } from 'react';
import {
  Col,
  Card,
  CardHeader,
  CardBody,
  Button,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Form,
  FormGroup,
  Label,
  Input,
} from 'reactstrap';
import DataTable from 'react-data-table-component';
import { toast } from 'react-toastify';
import { TextField, TablePagination } from '@mui/material';
import { bonusGroup, bonusAccount,bonusSync } from 'Services';
import { Wifi, Edit, Trash,RotateCw } from 'react-feather';


const BonusPage = () => {
  const [modal, setModal] = useState(false);
  const [deleteModal, setDeleteModal] = useState(false);
  const [addModalOpen, setAddModalOpen] = useState(false);
  const [unlimited, setUnlimited] = useState(false);
  const [formValues, setFormValues] = useState({
    groupName: '',
    platform: '',
    percentage: '', 
    type: '', 
  });
  const [accountFormValues, setAccountFormValues] = useState({
    accountNumber: '',
    bonusPercentage: '',
    startDate: new Date().toISOString().split('T')[0], 
    endDate: '',
    bonusAmount: '',
  });
  const [selectedRows, setSelectedRows] = useState([]);
  const [data, setData] = useState([]); // Initialize data as an empty array
  const [searchTerm, setSearchTerm] = useState('');
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [showAccountView, setShowAccountView] = useState(false);
  const [selectedGroup, setSelectedGroup] = useState(null);
  const [filteredAccountData, setFilteredAccountData] = useState([]); // State for filtered accounts
  const [editModalOpen, setEditModalOpen] = useState(false);
  const [editingAccount, setEditingAccount] = useState(null);
  const [accountSearchTerm, setAccountSearchTerm] = useState('');

  // Inside your component
const [syncing, setSyncing] = useState(false);

const handleSync = async () => {
  setSyncing(true); // Start syncing (icon rotation)

  try {
    // API call to update the sync field to 1 for slno = 1
    const response = await bonusSync.updateBonusSync(1, { sync: 1 });
    
    if (response) {
      toast.success('Sync.........!');
    } else {
      throw new Error('Failed to update sync.');
    }
  } catch (error) {
    toast.error(`Error syncing: ${error.message}`);
  }

  // Stop syncing after 30 seconds
  setTimeout(() => {
    setSyncing(false);
  }, 30000);
};

  const toggle = () => setModal(!modal);
  const toggleDeleteModal = () => setDeleteModal(!deleteModal);
  const toggleAddModal = (row) => {
    setAddModalOpen(!addModalOpen);
    setSelectedGroup(row);
  };

   // State for the account form in the edit modal
   const [editFormValues, setEditFormValues] = useState({
    accountNumber: '',
    bonusPercentage: '',
    startDate: '',
    endDate: '',
    startBalance: '',
    lastDorderid: '',
    lastWorderid: '',
    stopoutpecentage: '',
    accounttype: '',
  });

   // Toggle Edit Modal
   const toggleEditModal = (row) => {
    setEditingAccount(row);  // Set the selected row for editing
    setEditFormValues({
      accountNumber: row.accountnumber || '',
      bonusPercentage: row.percentage || '',
      startDate: row.startdate || '',
      endDate: row.enddate || '',
      startBalance: row.startbalance || '',
      lastDorderid: row.lastDorderid || '',
      lastWorderid: row.lastWorderid || '',
      stopoutpecentage: row.stopoutpecentage || '',
      accounttype: row.accounttype || '',
    });
    setEditModalOpen(!editModalOpen);
  };
  
  // Handle form input changes
  const handleEditInputChange = (e) => {
    const { name, value } = e.target;
    setEditFormValues({ ...editFormValues, [name]: value });
  };

  // Handle account search input change
const handleAccountSearch = (event) => {
  setAccountSearchTerm(event.target.value);
  setPage(0);
};
// Filter accounts based on the search term
const filteredAccounts = filteredAccountData.filter((account) =>
  Object.values(account).some(
    (value) => value && value.toString().toLowerCase().includes(accountSearchTerm.toLowerCase())
  )
);

   // Submit updated data
   const handleEditSubmit = async () => {
    const updatedData = {
      accountnumber: editFormValues.accountNumber,
      percentage: editFormValues.bonusPercentage,
      startdate: editFormValues.startDate,
      enddate: editFormValues.endDate,
      startbalance: editFormValues.startBalance,
      lastDorderid: editFormValues.lastDorderid,
      lastWorderid: editFormValues.lastWorderid,
      stopoutpecentage: editFormValues.stopoutpecentage,
      accounttype: editFormValues.accounttype,
    };

    try {
      const response = await bonusAccount.updateBonusAccount(editingAccount.slno, updatedData);

      if (response) {
        toast.success('Bonus account updated successfully!');
        // Refresh the data table here if needed
        setEditModalOpen(false);  // Close the modal after successful submission
      } else {
        throw new Error('Failed to update bonus account.');
      }
    } catch (error) {
      toast.error(`Error updating bonus account: ${error.message}`);
    }
  };

  



  // Fetch data from the bonusGroup table
  useEffect(() => {
    const fetchBonusGroups = async () => {
      try {
        const response = await bonusGroup.BonusGroup();
        if (response && Array.isArray(response)) {
          setData(response); // Set the data
        } else {
          setData([]); // Default to empty array if no data
        }
      } catch (error) {
        toast.error('Error fetching bonus group data');
      }
    };
    fetchBonusGroups();
  }, []);

  

  // Handle form input changes for the bonus group
  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormValues({ ...formValues, [name]: value });
  };

  // Handle form input changes for the new account
  const handleAccountInputChange = (e) => {
    const { name, value } = e.target;
    setAccountFormValues({ ...accountFormValues, [name]: value });
  };

  // Handle unlimited checkbox toggle
  const handleUnlimitedChange = (e) => {
    setUnlimited(e.target.checked);
    if (e.target.checked) {
      setAccountFormValues({ ...accountFormValues, endDate: '' });
    }
  };

  const handleSearch = (event) => {
    setSearchTerm(event.target.value);
    setPage(0);
  };

  const handleSubmit = async () => {
    const newData = {
      groupname: formValues.groupName,
      platform: formValues.platform,
      percentage: formValues.percentage, // Include percentage
      typeofgroup: formValues.type, // Include type
      status: 1, // Default status as enabled (1)
    };

    try {
      const response = await bonusGroup.createBonusGroup(newData);

      if (response) {
        setData([...data, response]); // Add the new group to the data array
        toast.success('Bonus group added successfully!');
        toggle(); // Close the modal
      } else {
        throw new Error('Failed to add bonus group.');
      }
    } catch (error) {
      toast.error(`Error adding bonus group: ${error.message}`);
    }
  };

  // Submit handler for adding a new account
  const handleAccountSubmit = async () => {
    if (!selectedGroup || !selectedGroup.slno) {
      toast.error('Please select a group first.');
    }

    const accountTypeValue = 1; // If unlimited is checked, set accounttype to 1, otherwise 0

    const newAccountData = {
      accountnumber: accountFormValues.accountNumber,
      percentage: accountFormValues.bonusPercentage,
      startdate: accountFormValues.startDate,
      enddate: accountFormValues.endDate,
      groupid: selectedGroup.slno, // Pass the slno of the selected group as groupid
      accounttype: accountTypeValue, // Store the account type based on unlimited checkbox
      platform: selectedGroup.platform, // Pass the platform from the selected group
      bonusAmount: accountFormValues.bonusAmount // Pass the bonus amount from form state
    };

    try {
      const response = await bonusAccount.createBonusAccount(newAccountData);
      if (response) {
        toast.success('Bonus account added successfully!');
        toggleAddModal(); // Close the modal after successful submission
      } else {
        throw new Error('Failed to add bonus account.');
      }
    } catch (error) {
      toast.error(`Error adding bonus account: ${error.message}`);
    }
  };

// Mark this function as async
const handleEnableDisable = async (row) => {
  const updatedStatus = row.status === 1 ? 0 : 1;

  try {
    // Send the update request to the backend
    const response = await bonusGroup.updateBonusGroup(row.slno, { status: updatedStatus });

    // Log the API response to check if it's coming through correctly
    console.log('API Response:', response);

    if (response && response.slno) {
      // Ensure the response contains the updated status
      const updatedData = data.map((item) =>
        item.slno === row.slno ? { ...item, status: updatedStatus } : item
      );
      setData(updatedData); // Update the state with the new data
      toast.success(`Group ${row.groupname} is now ${updatedStatus === 1 ? 'Enabled' : 'Disabled'}`);
    } else {
      throw new Error('Failed to update the bonus group status.');
    }
  } catch (error) {
    console.error('Error updating status:', error);
    toast.error('Failed to update the status');
  }
};


  // Fetch filtered accounts by groupid
  const handleView = async (row) => {
    setSelectedGroup(row);

    try {
      const response = await bonusAccount.BonusAccount();
      if (response && Array.isArray(response)) {
        // Filter accounts by groupid
        const filteredAccounts = response.filter(account => account.groupid === row.slno);
        setFilteredAccountData(filteredAccounts);
        setShowAccountView(true);
      } else {
        toast.error('No accounts found for this group');
      }
    } catch (error) {
      toast.error('Error fetching bonus accounts');
    }
  };

  const handleBack = () => {
    setShowAccountView(false);
  };

  const getStatusText = (status) => (status === 1 ? 'Enabled' : 'Disabled');

const getStatus = (endDate) => {
  if (endDate === '0000-00-00') {
    return 'Active';
  }
  
  const today = new Date();
  const accountEndDate = new Date(endDate);

  return accountEndDate >= today ? 'Active' : 'Inactive';
};

const getStatusColor = (endDate) => {
  if (endDate === '0000-00-00') {
    return 'green';
  }
  
  const today = new Date();
  const accountEndDate = new Date(endDate);

  return accountEndDate >= today ? 'green' : 'red';
};


  const handleDelete = async () => {
    try {
      for (let row of selectedRows) {
        await bonusGroup.deleteBonusGroup(row.slno);
      }
      const updatedData = data.filter((row) => !selectedRows.includes(row));
      setData(updatedData);
      toast.success('Selected items deleted successfully!');
      toggleDeleteModal();
    } catch (error) {
      toast.error('Error deleting selected items');
    }
  };


  // Handler to remove an account
const handleRemoveAccount = async (row) => {
  try {
    await bonusAccount.deleteBonusAccount(row.slno);
    // After successful deletion, remove the account from the table data
    const updatedAccounts = filteredAccountData.filter(account => account.slno !== row.slno);
    setFilteredAccountData(updatedAccounts); // Update the state with remaining accounts
    toast.success('Account removed successfully!');
  } catch (error) {
    toast.error('Error removing the account.');
  }
};


const tableColumns = [
  { name: 'Sl. No', selector: row => row.slno, sortable: true },
  { name: 'Group Name', selector: row => row.groupname, sortable: true },
  { name: 'Platform', selector: row => row.platform, sortable: true },
  { name: 'Percentage', selector: row => row.percentage, sortable: true },
  { name: 'Type', selector: row => row.typeofgroup, sortable: true },
  {
    name: 'Status',
    cell: (row) => (
      <Button 
        color={row.status === 1 ? 'primary' : 'danger'} 
        onClick={() => handleEnableDisable(row)}>
        {getStatusText(row.status)}
      </Button>
    ),
  },
  {
    name: 'Actions',
    cell: (row) => (
      <Fragment>
        <Button color="info" onClick={() => handleView(row)} style={{ marginRight: '10px' }}>
          View
        </Button>
        <Button color="primary" onClick={() => toggleAddModal(row)}>
          Add
        </Button>
      </Fragment>
    ),
  },
];


  const filteredData = (data || []).filter((row) =>
    Object.values(row).some(
      (value) => value && value.toString().toLowerCase().includes(searchTerm.toLowerCase())
    )
  );

  return (
    <Fragment>
       {/* CSS within JSX */}
       <style>
        {`
          .rotate-icon {
            animation: rotate 1s linear infinite;
          }

          @keyframes rotate {
            100% {
              transform: rotate(360deg);
            }
          }
        `}
      </style>
      <div style={{ height: '30px' }} className="container-fluid">
        <div className="page-title" style={{ display: 'none' }}>
          <div className="row">
            <div className="col-6">
              {/* <h3></h3> */}
            </div>
            <div className="col-6">
              <ol className="breadcrumb">
                <li className="breadcrumb-item">
                  <a href="/dashboard">Dashboard</a>
                </li>
                <li className="breadcrumb-item active">Clients</li>
              </ol>
            </div>
          </div>
        </div>
      </div>

      {!showAccountView ? (
        <Col sm="12">
          <Card>
            <CardHeader className="d-flex justify-content-between align-items-center">
              <h5>Bonus Group</h5>
              <div>
                <Button color="primary" onClick={toggle} style={{ marginRight: '15px' }}>Add</Button>
                <Button color="danger" onClick={toggleDeleteModal} disabled={selectedRows.length === 0}>
                  Delete
                </Button>
                <Button color="warning" style={{ marginLeft: '15px', marginRight: '15px' }} onClick={handleSync}>
                  {syncing ? <RotateCw className="rotate-icon" /> : "Sync"}
                </Button>
              </div>
            </CardHeader>
            <CardBody>
              <TextField
                label="Group Search"
                variant="outlined"
                fullWidth
                sx={{ marginBottom: 2 }}
                onChange={handleSearch}
              />
              <DataTable
                data={filteredData.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)}
                columns={tableColumns}
                selectableRows
                onSelectedRowsChange={(rows) => setSelectedRows(rows.selectedRows)}
              />
              <TablePagination
                 rowsPerPageOptions={[10, 25, 50, 100, 250, 500, 1000]}
                 component="div"
                 count={filteredData.length}
                 rowsPerPage={rowsPerPage}
                 page={page}
                 onPageChange={(event, newPage) => setPage(newPage)}
                 onRowsPerPageChange={(event) => setRowsPerPage(parseInt(event.target.value, 10))}
                 sx={{
                   '& .MuiTablePagination-selectLabel': {
                     marginBottom: 0,
                   },
                   '& .MuiTablePagination-displayedRows': {
                     marginBottom: 0,
                   },
                 }}
               />
             
            </CardBody>
          </Card>
        </Col>
      ) : (
        <Col sm="12">
          <Card>
            <CardHeader className="d-flex justify-content-between align-items-center">
              <h5>Account View for {selectedGroup?.groupname}</h5>
              <Button color="secondary" onClick={handleBack}>Back</Button>
            </CardHeader>
            <CardBody>
  <TextField
    label="Account Search"
    variant="outlined"
    fullWidth
    sx={{ marginBottom: 2 }}
    onChange={handleAccountSearch}
  />
  <DataTable
    data={filteredAccounts} // Use the filtered account data
    columns={[
      { name: 'Sl. No', selector: row => row.slno, sortable: true },
      { name: 'Account No', selector: row => row.accountnumber, sortable: true },
      { name: 'Percentage', selector: row => row.percentage, sortable: true },
      { name: 'Start Date', selector: row => row.startdate, sortable: true },
      { name: 'End Date', selector: row => row.enddate, sortable: true },
      {
        name: 'Status',
        cell: (row) => (
          <Wifi
            color={row.accounttype === '0' ? 'red' : getStatusColor(row.enddate)}
          />
        ),
        sortable: true,
      },
      {
        name: 'Account Type',
        selector: row => row.accounttype === '1' ? 'Active' : 'InActive',
        sortable: true
      },
      { name: 'Initial Balance', selector: row => row.startbalance, sortable: true },
      { name: 'Last Deposit ID', selector: row => row.lastDorderid, sortable: true },
      { name: 'Last Withdrawal ID', selector: row => row.lastWorderid, sortable: true },
      { name: 'StopOut Margin %', selector: row => row.stopoutpecentage, sortable: true },
      {
        name: 'Actions',
        cell: (row) => (
          <Fragment>
            <Button color="warning" onClick={() => toggleEditModal(row)} style={{ marginRight: '10px' }}>
              <Edit size={16} />
            </Button>
            <Button color="danger" onClick={() => handleRemoveAccount(row)}>
              <Trash size={16} />
            </Button>
          </Fragment>
        ),
      },
    ]}
  />
</CardBody>

          </Card>
        </Col>
      )}

      {/* Modal for Adding New Account */}
      <Modal isOpen={addModalOpen} toggle={toggleAddModal}>
        <ModalHeader toggle={toggleAddModal}>Add New Account</ModalHeader>
        <ModalBody>
          <Form>
            <FormGroup>
              <Label for="accountNumber">Account Number</Label>
              <Input
                type="text"
                name="accountNumber"
                id="accountNumber"
                value={accountFormValues.accountNumber}
                onChange={handleAccountInputChange}
                placeholder="Enter account number"
              />
            </FormGroup>
             {/* Disabled input field to show platform from selected bonus group */}
             <FormGroup>
              <Label for="platform">Platform</Label>
              <Input
                type="text"
                name="platform"
                id="platform"
                value={selectedGroup?.platform || ''} // Display platform from selected group
                disabled // Make it read-only
              />
            </FormGroup>
            <FormGroup>
              <Label for="bonusPercentage">Bonus Percentage</Label>
              <Input
                type="number"
                name="bonusPercentage"
                id="bonusPercentage"
                value={accountFormValues.bonusPercentage}
                onChange={handleAccountInputChange}
                placeholder="Enter bonus percentage"
              />
            </FormGroup>
            <FormGroup>
              <Label for="startDate">Start Date</Label>
              <Input
                type="date"
                name="startDate"
                id="startDate"
                value={accountFormValues.startDate}
                onChange={handleAccountInputChange}
              />
            </FormGroup>
            <FormGroup>
              <Label for="endDate">End Date</Label>
              <Input
                type="date"
                name="endDate"
                id="endDate"
                value={accountFormValues.endDate}
                onChange={handleAccountInputChange}
                disabled={unlimited}
              />
            </FormGroup>
            <FormGroup>
              <Label for="bonusAmount">Bonus Amount</Label>
              <Input
                type="number"
                name="bonusAmount"
                id="bonusAmount"
                value={accountFormValues.bonusAmount}
                onChange={handleAccountInputChange}
                placeholder="Enter bonus amount"
              />
            </FormGroup>
          </Form>
        </ModalBody>
        <ModalFooter>
          <Button color="primary" onClick={handleAccountSubmit}>Submit</Button>
          <Button color="secondary" onClick={toggleAddModal}>Cancel</Button>
        </ModalFooter>
      </Modal>

      {/* Modal for Adding Bonus Group */}
      <Modal isOpen={modal} toggle={toggle}>
        <ModalHeader toggle={toggle}>Add Bonus Group</ModalHeader>
        <ModalBody>
          <Form>
            <FormGroup>
              <Label for="groupName">Group Name</Label>
              <Input
                type="text"
                name="groupName"
                id="groupName"
                value={formValues.groupName}
                onChange={handleInputChange}
              />
            </FormGroup>
            <FormGroup>
              <Label for="platform">Platform</Label>
              <Input
                type="select"
                name="platform"
                id="platform"
                value={formValues.platform}
                onChange={handleInputChange}
              >
                <option value="">Select Platform</option>
                <option value="MT4">MT4</option>
                <option value="MT5">MT5</option>
              </Input>
            </FormGroup>
            <FormGroup>
              <Label for="percentage">Percentage</Label>
              <Input
                type="number"
                name="percentage"
                id="percentage"
                value={formValues.percentage}
                onChange={handleInputChange}
                placeholder="Enter bonus percentage"
              />
            </FormGroup>
            <FormGroup>
              <Label for="type">Type</Label>
              <Input
                type="select"
                name="type"
                id="type"
                value={formValues.type}
                onChange={handleInputChange}
              >
                <option value="">Select Group Type</option>
                <option value="Trading Group">Trading Group</option>
                <option value="Manual Group">Manual Group</option>
              </Input>
            </FormGroup>
          </Form>
        </ModalBody>
        <ModalFooter>
          <Button color="primary" onClick={handleSubmit}>Submit</Button>
          <Button color="secondary" onClick={toggle}>Cancel</Button>
        </ModalFooter>
      </Modal>

      {/* Modal for Deleting Bonus Groups */}
      <Modal isOpen={deleteModal} toggle={toggleDeleteModal}>
        <ModalHeader toggle={toggleDeleteModal}>Confirm Delete</ModalHeader>
        <ModalBody>
          Are you sure you want to delete the selected items?
        </ModalBody>
        <ModalFooter>
          <Button color="danger" onClick={handleDelete}>Delete</Button>
          <Button color="secondary" onClick={toggleDeleteModal}>Cancel</Button>
        </ModalFooter>
      </Modal>

       {/* Edit Modal */}
       <Modal isOpen={editModalOpen} toggle={toggleEditModal}>
  <ModalHeader toggle={toggleEditModal}>Edit Bonus Account</ModalHeader>
  <ModalBody>
    <Form>
      <FormGroup>
        <Label for="accountNumber">Account Number</Label>
        <Input
          type="text"
          name="accountNumber"
          id="accountNumber"
          value={editFormValues.accountNumber}
          onChange={handleEditInputChange}
          disabled // Disable the Account Number field
        />
      </FormGroup>
      <FormGroup>
        <Label for="bonusPercentage">Bonus Percentage</Label>
        <Input
          type="number"
          name="bonusPercentage"
          id="bonusPercentage"
          value={editFormValues.bonusPercentage}
          onChange={handleEditInputChange}
        />
      </FormGroup>
      <FormGroup>
        <Label for="startDate">Start Date</Label>
        <Input
          type="date"
          name="startDate"
          id="startDate"
          value={editFormValues.startDate}
          onChange={handleEditInputChange}
        />
      </FormGroup>
      <FormGroup>
        <Label for="endDate">End Date</Label>
        <Input
          type="date"
          name="endDate"
          id="endDate"
          value={editFormValues.endDate}
          onChange={handleEditInputChange}
        />
      </FormGroup>
      <FormGroup>
        <Label for="startBalance">Start Balance</Label>
        <Input
          type="number"
          name="startBalance"
          id="startBalance"
          value={editFormValues.startBalance}
          onChange={handleEditInputChange}
          disabled // Disable the Start Balance field
        />
      </FormGroup>
      <FormGroup>
        <Label for="lastDorderid">Last Deposit ID</Label>
        <Input
          type="number"
          name="lastDorderid"
          id="lastDorderid"
          value={editFormValues.lastDorderid}
          onChange={handleEditInputChange}
          disabled // Disable the Last Deposit ID field
        />
      </FormGroup>
      <FormGroup>
        <Label for="lastWorderid">Last Withdrawal ID</Label>
        <Input
          type="number"
          name="lastWorderid"
          id="lastWorderid"
          value={editFormValues.lastWorderid}
          onChange={handleEditInputChange}
          disabled // Disable the Last Withdrawal ID field
        />
      </FormGroup>

      {/* Account Type Toggle Button */}
      <FormGroup>
        <Label for="accountType">Account Status</Label>
        <div>
          <Button
            color={editFormValues.accounttype === '1' ? 'success' : 'danger'}
            onClick={() =>
              setEditFormValues({
                ...editFormValues,
                accounttype: editFormValues.accounttype === '1' ? '0' : '1',
              })
            }
          >
            {editFormValues.accounttype === '1' ? 'Enabled' : 'Disabled'}
          </Button>
        </div>
      </FormGroup>

      <FormGroup>
        <Label for="stopoutpecentage">StopOut Margin %</Label>
        <Input
          type="number"
          name="stopoutpecentage"
          id="stopoutpecentage"
          value={editFormValues.stopoutpecentage}
          onChange={handleEditInputChange}
        />
      </FormGroup>
    </Form>
  </ModalBody>
  <ModalFooter>
    <Button color="primary" onClick={handleEditSubmit}>Save Changes</Button>
    <Button color="secondary" onClick={toggleEditModal}>Cancel</Button>
  </ModalFooter>
</Modal>


    </Fragment>
  );
};

export default BonusPage;


