import React from 'react';
import { Card, CardHeader, CardBody } from 'reactstrap';

const Agreements = ({ agreement = {} }) => {
    return (<React.Fragment>
        {agreement?.map((agreement, agreementIndex) => {
    return (
        <React.Fragment key={agreementIndex}>
            {/* Card for each Agreement */}
            <Card>
                {/* Display the title of each agreement in the CardHeader */}
                <CardHeader className='p-3'>
                    {agreement?.title}
                </CardHeader>
                
                <CardBody>
                    {agreement?.members?.map((member, memIndex) => {
                        return (
                            <React.Fragment key={memIndex}>
                                <p>IB - {member?.Member?.firstName + ' ' + member?.Member?.lastName}</p>
                                {member?.AccountTypeValues?.map((accType, acctypeInd) => {
                                    if (accType?.accountTypeId === 14 || accType?.accountTypeId === 2) {
                                        return (
                                            <React.Fragment key={acctypeInd}>
                                                <p className='mb-0'>{accType?.AccountType?.title} - {accType?.rebate}</p>
                                            </React.Fragment>
                                        );
                                    }
                                    return null; // Don't render anything if the condition is not met
                                })}
                                <hr />
                            </React.Fragment>
                        );
                    })}
                </CardBody>
            </Card>
        </React.Fragment>
    );
})}

    </React.Fragment>)
};
export default Agreements;