import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { toast } from 'react-toastify';
import {
    Table,
    Button,
    Modal,
    ModalHeader,
    ModalBody,
    ModalFooter,
    Input,
    Card,
    CardBody,
    CardHeader,
    Nav,
    NavItem,
    NavLink,
    TabContent,
    TabPane,
    Label,
} from 'reactstrap';
import { createDocument as createdocs, updateDocument as updatedocs, deleteDocument as deletedocs, addRecord} from '../../../Services/managedocs.service';

// Make sure this import path is correct
import classnames from 'classnames';

const DocumentsLetters = () => {
    const [documents, setDocuments] = useState([]);
    const [activeTab, setActiveTab] = useState('1');
    const [docmodal, setdocModal] = useState(false);
    const [lettermodal, setletterModal] = useState(false);
    const [templatemodal, settemplateModal] = useState(false);
    const [selectedDoc, setSelectedDoc] = useState('');
    const [selectedletter, setSelectedletter] = useState('');
    const [selectedtemplate, setSelectedtemplate] = useState('');
    const [documentSearch, setdocumentSearch] = useState('');
    const [letterSearch, setletterSearch] = useState('');
    const [templateSearch, settemplateSearch] = useState('');
    const [file, setFile] = useState(null);

    const toggleTab = (tab) => {
        if (activeTab !== tab) setActiveTab(tab);
    };

    const [managedocData, setmanagedocData] = useState([
        {

            docName: '',
            docType: '',
            owner: '',
            expiry_date: '',
            companydoc: '',
            documentUrl: '' // For file uploads

        }

        // Add more data here
    ]);
    const [manageletterData, setmanageletterData] = useState([
        {
            empNo: '',
            empName: '',
            template: '',
            category: '',
            classification: '',
            effective_date: '',
            status: '',
            generated_by: '',
            generated_on: ''
        }
        // Add more data here
    ]);
    const [templateData, settemplateData] = useState([
        {

            template: '',
            category: '',
            classification: '',
            created_by: '',
            created_on: '',
            updated_by: '',
            updated_on: ''
        }
        // Add more data here
    ]);
    const toggledocModal = (document = null) => {
        setSelectedDoc(document || {
            docName: '',
            docType: '',
            owner: '',
            expiry_date: '',
            companydoc: '',
            documentUrl: '',
        });
        setdocModal(!docmodal);
    };


    const toggleletterModal = (letter = null) => {
        setSelectedletter(letter);
        setletterModal(!lettermodal);
    };
    const toggletemplateModal = (template = null) => {
        setSelectedtemplate(template);
        settemplateModal(!templatemodal);
    };



    const handleLetterInputChange = (e) => {
        const { name, value } = e.target;
        setmanageletterData({ ...manageletterData, [name]: value });
    };

    const handleTemplateInputChange = (e) => {
        const { name, value } = e.target;
        settemplateData({ ...templateData, [name]: value });
    };



    const handleSaveLetter = () => {
        // Logic to save letter, e.g., API call
        const updatedLetterData = manageletterData.map((letter) =>
            letter.id === manageletterData.id ? manageletterData : letter
        );
        setmanageletterData(updatedLetterData);
        toggleletterModal();
    };

    const handleSaveTemplate = () => {
        // Logic to save template, e.g., API call
        const updatedTemplateData = templateData.map((template) =>
            template.id === templateData.id ? templateData : template
        );
        settemplateData(updatedTemplateData);
        toggletemplateModal();
    };

    //Fetch all documents when the component mounts
    // const fetchDocuments = async () => {
    //   try {
    //     const data = await fetchdocs();
    //     setDocuments(data);
    //   } catch (error) {
    //     console.error('Failed to load documents:', error);
    //   }
    // };

    //     useEffect(() => {
    //     fetchDocuments();
    //   }, []);
    //Other functions...

    // Handles form input changes for document details
    const handleDocChange = (e) => {
        const { name, value } = e.target;
        setSelectedDoc({
            ...selectedDoc,
            [name]: value, // Ensure the selected option value is being captured
        });
    };

    const documentUpload = (event) => {
        setFile(event.target.files[0]);
        console.log("file is changes ", event.target.files)
    };




    // Initializes the form for creating a new document
    const initializeCreateDocForm = () => {
        setSelectedDoc({
            docName: '',
            docType: '',
            owner: '',
            expiry_date: '',
            companydoc: '',
            documentUrl: '' // For file uploads
        });
        toggledocModal(); // Opens the modal
    };

    // Initializes the form for updating an existing document
    const initializeUpdateDocForm = (document) => {
        setSelectedDoc(document);
        toggledocModal(); // Opens the modal
    };

    // Deletes a managed document by ID
    const deleteManagedDoc = async (id) => {
        try {
            await deletedocs(id); // Assuming this is the API call to delete the document
            toast.success('Document deleted successfully!');
            setmanagedocData((prevDocs) => prevDocs.filter((doc) => doc.id !== id));
        } catch (error) {
            console.error('Error deleting document:', error);
            toast.error('Failed to delete document. Please try again.');
        }
    };

    // Handles saving the document (create or update)
    const handleSaveDoc = async (e) => {
        e.preventDefault();
        console.log("data is ", e);

        if (selectedDoc && !selectedDoc.docName || !selectedDoc.docType || !selectedDoc.owner) {
            toast.error('Please fill in all required fields.');
            return;
        }
        const formDataValue = new FormData();

        if (file) {
            console.log("File exists:", file);

            try {
                formDataValue.append('customerId', 2000112230);
                formDataValue.append('ID', file);


                console.log("File successfully appended.");

                // Log the formData contents
                for (let [key, value] of formDataValue.entries()) {
                    console.log(`${key}:`, value); // Log all FormData key-value pairs
                }
            } catch (err) {
                console.error("Error appending file:", err);
            }

        } else {
            console.log("File is undefined or null.");
        }
        const res = await createdocs(formDataValue);
        console.log('res: ', res);

        // Validate required fields

        console.log("good");
        // Prepare the payload for the API request
        const payload = {
            ...selectedDoc,
            
        };
        console.log("payload is ", payload);
        try {
            let response;

            if (selectedDoc.id) {
                // Call the actual update API function
                response = await updatedocs(selectedDoc.id, payload);
                toast.success('Document updated successfully!');
            } else {
                // Call the actual create API function
                response = await addRecord(payload);
                // Add the newly created document to the managedocData state
                setmanagedocData((prevDocs) => [...prevDocs, response]);
                toast.success('Document added successfully!');
            }
            console.log("response", response);

            // Refresh the documents list after saving
            //   await fetchDocuments(); // Ensure fetchdocData is now correctly defined and can be called here

            // Close the document modal after saving
            toggledocModal();
        } catch (error) {
            console.error('Error saving document:', error);
            toast.error('Failed to save document. Please try again.');
        }
    };




    // Manage Document Modal
    const renderdocModal = () => {
        if (!selectedDoc) return null;


        return (
            <Modal isOpen={docmodal} toggle={() => toggledocModal()}>
                <ModalHeader toggle={() => toggledocModal()}> Document Details</ModalHeader>
                <ModalBody>
                    <Label>Document Name: </Label>
                    <Input
                        type="text"
                        name="docName"
                        value={selectedDoc.docName}
                        onChange={handleDocChange}
                    />

                    <Label>Document Type: </Label>
                    <Input
                        type="text"
                        name="docType"
                        value={selectedDoc.docType}
                        onChange={handleDocChange}
                    />

                    <Label>Owner: </Label>
                    <Input
                        type="text"
                        name="owner"
                        value={selectedDoc.owner}
                        onChange={handleDocChange}
                    />

                    <Label>Expiry Date: </Label>
                    <Input
                        type="date"
                        name="expiry_date"
                        value={selectedDoc.expiry_date}
                        onChange={handleDocChange}
                    />
                    <Label>Company Document: </Label>
                    <Input
                        type="select"
                        name="companydoc"
                        value={selectedDoc.companydoc}
                        onChange={handleDocChange}
                    >
                        <option value="">Select...</option> {/* Optional default option */}
                        <option value="YES">YES</option>
                        <option value="NO">NO</option>
                    </Input>

                    {/* New Field for Document Attachment */}
                    <Label>Attach Document: </Label>
                    <Input
                        type="file"
                        name="document"
                        onChange={documentUpload} // Handle the file input change
                    />


                </ModalBody>
                <ModalFooter>
                    <Button color="primary" onClick={handleSaveDoc}>Save</Button>{' '}
                    <Button color="secondary" onClick={() => toggledocModal()}>Cancel</Button>
                </ModalFooter>
            </Modal>

        );
    };

    // HR Letter Modal
    const renderletterModal = () => {
        if (!selectedletter) return null;

        const handleAddLetter = () => {
            const newLetter = { ...manageletterData, id: manageletterData.length + 1 };
            setmanageletterData([...manageletterData, newLetter]);
            toggleletterModal();
        };


        const handleLetterChange = (e) => {
            const { name, value } = e.target;
            setSelectedletter({
                ...selectedletter,
                [name]: value,
            });
        };

        const handleSaveLetter = () => {
            const updatedLetters = manageletterData.map((letter) =>
                letter.id === selectedletter.id ? selectedletter : letter
            );
            setmanageletterData(updatedLetters);
            toggleletterModal();
        };

        return (
            <Modal isOpen={lettermodal} toggle={() => toggleletterModal()}>
                <ModalHeader toggle={() => toggleletterModal()}>Edit HR Letter Details</ModalHeader>
                <ModalBody>
                    <Label>Employee No.: </Label>
                    <Input
                        type="text"
                        name="empNo"
                        value={selectedletter.empNo}
                        onChange={handleLetterChange}
                    />

                    <Label>Employee Name: </Label>
                    <Input
                        type="text"
                        name="empName"
                        value={selectedletter.empName}
                        onChange={handleLetterChange}
                    />

                    <Label>Template: </Label>
                    <Input
                        type="text"
                        name="template"
                        value={selectedletter.template}
                        onChange={handleLetterChange}
                    />

                    <Label>Category: </Label>
                    <Input
                        type="text"
                        name="category"
                        value={selectedletter.category}
                        onChange={handleLetterChange}
                    />

                    <Label>Classification: </Label>
                    <Input
                        type="text"
                        name="classification"
                        value={selectedletter.classification}
                        onChange={handleLetterChange}
                    />

                    <Label>Effective Date: </Label>
                    <Input
                        type="date"
                        name="effective_date"
                        value={selectedletter.effective_date}
                        onChange={handleLetterChange}
                    />

                    <Label>Status: </Label>
                    <Input
                        type="text"
                        name="status"
                        value={selectedletter.status}
                        onChange={handleLetterChange}
                    />

                    <Label>Generated By: </Label>
                    <Input
                        type="text"
                        name="generated_by"
                        value={selectedletter.generated_by}
                        onChange={handleLetterChange}
                    />

                    <Label>Generated On: </Label>
                    <Input
                        type="date"
                        name="generated_on"
                        value={selectedletter.generated_on}
                        onChange={handleLetterChange}
                    />
                </ModalBody>
                <ModalFooter>
                    <Button color="primary" onClick={handleSaveLetter}>Save</Button>{' '}
                    <Button color="secondary" onClick={() => toggleletterModal()}>Cancel</Button>
                </ModalFooter>
            </Modal>
        );
    };


    //Template Modal
    const rendertemplateModal = () => {
        if (!selectedtemplate) return null;

        const handleAddTemplate = () => {
            const newTemplate = { ...templateData, id: templateData.length + 1 };
            settemplateData([...templateData, newTemplate]);
            toggletemplateModal();
        };


        const handleTemplateChange = (e) => {
            const { name, value } = e.target;
            setSelectedtemplate({
                ...selectedtemplate,
                [name]: value,
            });
        };

        const handleSaveTemplate = () => {
            const updatedTemplates = templateData.map((template) =>
                template.id === selectedtemplate.id ? selectedtemplate : template
            );
            settemplateData(updatedTemplates);
            toggletemplateModal();
        };

        return (
            <Modal isOpen={templatemodal} toggle={() => toggletemplateModal()}>
                <ModalHeader toggle={() => toggletemplateModal()}>Edit Template Details</ModalHeader>
                <ModalBody>
                    <Label>Template: </Label>
                    <Input
                        type="text"
                        name="template"
                        value={selectedtemplate.template}
                        onChange={handleTemplateChange}
                    />

                    <Label>Category: </Label>
                    <Input
                        type="text"
                        name="category"
                        value={selectedtemplate.category}
                        onChange={handleTemplateChange}
                    />

                    <Label>Classification: </Label>
                    <Input
                        type="text"
                        name="classification"
                        value={selectedtemplate.classification}
                        onChange={handleTemplateChange}
                    />

                    <Label>Created By: </Label>
                    <Input
                        type="text"
                        name="created_by"
                        value={selectedtemplate.created_by}
                        onChange={handleTemplateChange}
                    />

                    <Label>Created On: </Label>
                    <Input
                        type="date"
                        name="created_on"
                        value={selectedtemplate.created_on}
                        onChange={handleTemplateChange}
                    />

                    <Label>Last Updated By: </Label>
                    <Input
                        type="text"
                        name="updated_by"
                        value={selectedtemplate.updated_by}
                        onChange={handleTemplateChange}
                    />

                    <Label>Last Updated On: </Label>
                    <Input
                        type="date"
                        name="updated_on"
                        value={selectedtemplate.updated_on}
                        onChange={handleTemplateChange}
                    />
                </ModalBody>
                <ModalFooter>
                    <Button color="primary" onClick={handleSaveTemplate}>Save</Button>{' '}
                    <Button color="secondary" onClick={() => toggletemplateModal()}>Cancel</Button>
                </ModalFooter>
            </Modal>
        );
    };



    const filteredletters = manageletterData.filter((letter) =>
        letter.empNo.toLowerCase().includes(letterSearch.toLowerCase()) ||
        letter.empName.toLowerCase().includes(letterSearch.toLowerCase()) ||
        letter.template.toLowerCase().includes(letterSearch.toLowerCase()) ||
        letter.category.toLowerCase().includes(letterSearch.toLowerCase()) ||
        letter.classification.toLowerCase().includes(letterSearch.toLowerCase()) ||
        letter.effective_date.toLowerCase().includes(letterSearch.toLowerCase()) ||
        letter.status.toLowerCase().includes(letterSearch.toLowerCase()) ||
        letter.generated_by.toLowerCase().includes(letterSearch.toLowerCase()) ||
        letter.generated_on.toLowerCase().includes(letterSearch.toLowerCase())
    );
    const filteredtemplates = templateData.filter((template) =>
        template.template.toLowerCase().includes(templateSearch.toLowerCase()) ||
        template.category.toLowerCase().includes(templateSearch.toLowerCase()) ||
        template.classification.toLowerCase().includes(templateSearch.toLowerCase()) ||
        template.created_by.toLowerCase().includes(templateSearch.toLowerCase()) ||
        template.created_on.toLowerCase().includes(templateSearch.toLowerCase()) ||
        template.updated_by.toLowerCase().includes(templateSearch.toLowerCase()) ||
        template.updated_on.toLowerCase().includes(templateSearch.toLowerCase())
    );
    return (
        <>
            <Nav tabs style={{ justifyContent: 'left', display: 'flex', flexWrap: 'wrap' }}>
                <NavItem>
                    <NavLink
                        className={classnames({ active: activeTab === '1' })}
                        onClick={() => toggleTab('1')}
                        style={{
                            cursor: 'pointer',
                            color: activeTab === '1' ? '#194995' : 'black',
                            borderBottom: activeTab === '1' ? '2px solid #194995' : 'none', // Blue underline for active tab
                            padding: '10px 15px', // Add padding for spacing
                            textAlign: 'center' // Center text if needed
                        }}
                    >
                        Manage Documents
                    </NavLink>
                </NavItem>
                <NavItem>
                    <NavLink
                        className={classnames({ active: activeTab === '2' })}
                        onClick={() => toggleTab('2')}
                        style={{
                            cursor: 'pointer',
                            color: activeTab === '2' ? '#194995' : 'black',
                            borderBottom: activeTab === '2' ? '2px solid #194995' : 'none',
                            padding: '10px 15px',
                            textAlign: 'center'
                        }}
                    >
                        Manage HR Letters
                    </NavLink>
                </NavItem>
                <NavItem>
                    <NavLink
                        className={classnames({ active: activeTab === '3' })}
                        onClick={() => toggleTab('3')}
                        style={{
                            cursor: 'pointer',
                            color: activeTab === '3' ? '#194995' : 'black',
                            borderBottom: activeTab === '3' ? '2px solid #194995' : 'none',
                            padding: '10px 15px',
                            textAlign: 'center'
                        }}
                    >
                        Custom Templates
                    </NavLink>
                </NavItem>
            </Nav>

            <TabContent activeTab={activeTab}>
                <TabPane tabId="1">
                    <Card>
                        <CardHeader>
                            <h3 style={{ fontSize: '24px', fontWeight: 'bold', color: '#194995', marginBottom: '20px' }}>
                                Manage Documents
                            </h3>
                            <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
                                <Input
                                    type="text"
                                    placeholder="Search Documents"
                                    value={documentSearch}
                                    onChange={(e) => setdocumentSearch(e.target.value)}
                                    style={{ width: '60%' }}
                                />
                                <Button onClick={() => initializeCreateDocForm({})} style={{ marginLeft: '10px' }}>
                                    Add
                                </Button>
                            </div>


                        </CardHeader>
                        <CardBody className="scrollable-table-container">
                            <Table bordered hover className="scrollable-table">
                                <thead>
                                    <tr>
                                        <th>Document Name</th>
                                        <th>Document Type</th>
                                        <th>Owner</th>
                                        <th>Expiry Date</th>
                                        <th>Company Document</th>
                                        <th>View</th>
                                        <th>Edit</th>
                                        <th>Delete</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {documents.map((document) => (
                                        <tr key={document.id}>
                                            <td>{document.docName}</td>
                                            <td>{document.docType}</td>
                                            <td>{document.owner}</td>
                                            <td>{document.expiry_date}</td>
                                            <td>{document.companydoc === "YES" ? "YES" : "NO"}</td>
                                            <td><a
                                                href={document.documentUrl}
                                                target="_blank"
                                                rel="noopener noreferrer"
                                                style={{ textDecoration: 'none' }} // Optional to remove underline if desired
                                            >
                                                <button className="btn btn-primary">View</button>
                                            </a>
                                            </td>
                                            <td>
                                                <Button color="primary" onClick={() => initializeUpdateDocForm(document)}>
                                                    Edit
                                                </Button>
                                            </td>
                                            <td>
                                                <Button color="danger" onClick={() => deleteManagedDoc(document.id)}>
                                                    Delete
                                                </Button>
                                            </td>
                                        </tr>
                                    ))}
                                </tbody>
                            </Table>
                        </CardBody>
                    </Card>
                </TabPane>
                <TabPane tabId="2">
                    <Card>
                        <CardHeader>
                            <h3 style={{ fontSize: '24px', fontWeight: 'bold', color: '#194995', marginBottom: '20px' }}>
                                Manage HR Letters
                            </h3>
                            <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
                                <Input
                                    type="text"
                                    placeholder="Search Letters"
                                    value={letterSearch}
                                    onChange={(e) => setletterSearch(e.target.value)}
                                    style={{ width: '60%' }}
                                />
                                <Button onClick={() => toggleletterModal({})} style={{ marginLeft: '10px' }}>
                                    Add
                                </Button>
                            </div>
                        </CardHeader>
                        <CardBody className="scrollable-table-container">
                            <Table bordered hover className="scrollable-table">
                                <thead>
                                    <tr>
                                        <th>Employee No.</th>
                                        <th>Employee Name</th>
                                        <th>Template</th>
                                        <th>Category</th>
                                        <th>Classification</th>
                                        <th>Effective Date</th>
                                        <th>Status</th>
                                        <th>Generated By</th>
                                        <th>Generated On</th>
                                        <th>Show</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {filteredletters.map((letter) => (
                                        <tr key={letter.id}>
                                            <td>{letter.empNo}</td>
                                            <td>{letter.empName}</td>
                                            <td>{letter.template}</td>
                                            <td>{letter.category}</td>
                                            <td>{letter.classification}</td>
                                            <td>{letter.effective_date}</td>
                                            <td>{letter.status}</td>
                                            <td>{letter.generated_by}</td>
                                            <td>{letter.generated_on}</td>
                                            <td>
                                                <Button color="primary" onClick={() => toggleletterModal(letter)}>
                                                    Show
                                                </Button>
                                            </td>
                                        </tr>
                                    ))}
                                </tbody>
                            </Table>
                        </CardBody>
                    </Card>
                </TabPane>
                <TabPane tabId="3">
                    <Card>
                        <CardHeader>
                            <h3 style={{ fontSize: '24px', fontWeight: 'bold', color: '#194995', marginBottom: '20px' }}>
                                Custom Templates
                            </h3>
                            <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
                                <Input
                                    type="text"
                                    placeholder="Search Templates"
                                    value={templateSearch}
                                    onChange={(e) => settemplateSearch(e.target.value)}
                                    style={{ width: '60%' }}
                                />
                                <Button onClick={() => toggletemplateModal({})} style={{ marginLeft: '10px' }}>
                                    Add
                                </Button>
                            </div>
                        </CardHeader>

                        <CardBody className="scrollable-table-container">
                            <Table bordered hover className="scrollable-table">
                                <thead>
                                    <tr>
                                        <th>Template</th>
                                        <th>Category</th>
                                        <th>Classification</th>
                                        <th>Created By</th>
                                        <th>Created On</th>
                                        <th>Last Updated By</th>
                                        <th>Last Updated On</th>
                                        <th>Show</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {filteredtemplates.map((template) => (
                                        <tr key={template.id}>
                                            <td>{template.template}</td>
                                            <td>{template.category}</td>
                                            <td>{template.classification}</td>
                                            <td>{template.created_by}</td>
                                            <td>{template.created_on}</td>
                                            <td>{template.updated_by}</td>
                                            <td>{template.updated_on}</td>
                                            <td>
                                                <Button color="primary" onClick={() => toggletemplateModal(template)}>
                                                    Show
                                                </Button>
                                            </td>
                                        </tr>
                                    ))}
                                </tbody>
                            </Table>
                        </CardBody>
                    </Card>
                </TabPane>
            </TabContent>
            {renderdocModal()}
            {renderletterModal()}
            {rendertemplateModal()}
        </>
    );
};

export default DocumentsLetters;
