import React, { useState } from 'react';
import {
  Table,
  Button,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Input,
  Card,
  CardBody,
  CardHeader,
  Nav,
  NavItem,
  NavLink,
  TabContent,
  TabPane,
  Label,
} from 'reactstrap';
import classnames from 'classnames';



const AdminTimeManagment = () => {
  const [activeTab, setActiveTab] = useState('1');
  const [modal, setModal] = useState(false);
  const [selectedLeave, setSelectedLeave] = useState(null);
  const [vacationSearch, setvacationSearch] = useState('');
  const [hourlyLeaveSearch, sethourlyLeaveSearch] = useState('');
  const [adjustmentSearch, setadjustmentSearch] = useState('');
  const [movedSearch, setmovedSearch] = useState('');
  // Toggle between tabs
  const toggleTab = (tab) => {
    if (activeTab !== tab) setActiveTab(tab);
  };

  // State for managing Vacation Days table data
  const [vacationData, setVacationData] = useState([
    {
      id: 1,
      empName: 'Alaa Mohamed Ismail Ahmed Mohamed',
      empNo: '101009',
      transactionType: 'Annual Vacations',
      fromDate: 'Thu, 2022-09-01',
      toDate: 'Fri, 2022-09-30',
      resumptionDate: 'Sat, 2022-10-01',
      receivedOn: 'Thu, 2022-09-01 03:41 PM',
      noOfDays: '30.00 Days',
      status: 'Added by HR',
    },
    // Add more data here
  ]);

  // State for managing Hourly Leaves table data
  const [hourlyLeaveData, setHourlyLeaveData] = useState([
    {
      id: 1,
      empName: 'Ahmed Ibrahim',
      empNo: '100458',
      transactionType: 'Hourly Leave',
      fromDate: 'Thu, 2023-05-18',
      toDate: 'Thu, 2023-05-18',
      hours: '4 Hours',
      status: 'Approved by Manager',
    },
    // Add more data here
  ]);

  // State for managing Manage Adjustments table data
  const [adjustmentsData, setAdjustmentsData] = useState([
    {
      id: 1,
      empName: 'Alaa Mohamed Ismail Ahmed Mohamed',
      empNo: '101009',
      adjustmentName: 'Annual Vacations',
      amount: '-19.20 Days',
      effectiveDate: 'Wed, 2023-02-15',
    },
    {
      id: 2,
      empName: 'Atul Madaan',
      empNo: '100512',
      adjustmentName: 'Annual Vacations',
      amount: '6.00 Days',
      effectiveDate: 'Tue, 2023-08-15',
    },
    // Add more data here
  ]);
  const [MovedData, setMovedData] = useState([
    {
      id: 1,
      empName: 'Alaa Mohamed Ismail Ahmed Mohamed',
      empNo: '101009',
      vacationName: 'Annual Vacations',
      amount: '-19.20 Days',
      effectiveDate: 'Wed, 2023-02-15',
    },
    {
      id: 2,
      empName: 'Atul Madaan',
      empNo: '100512',
      vacationName: 'Annual Vacations',
      amount: '6.00 Days',
      effectiveDate: 'Tue, 2023-08-15',
    },
    // Add more data here
  ]);

  // Toggle modal visibility
  const toggleModal = (leave = null) => {
    setSelectedLeave(leave);
    setModal(!modal);
  };

  // Modal content render logic
  const renderModal = () => {
    if (!selectedLeave) return null;

    return (
      <Modal isOpen={modal} toggle={() => toggleModal()}>
        <ModalHeader toggle={() => toggleModal()}>Leave Details</ModalHeader>
        <ModalBody>
          <Label>Employee Name: </Label>
          <p>{selectedLeave.empName}</p>

          <Label>Transaction Type: </Label>
          <p>{selectedLeave.transactionType}</p>

          <Label>From Date: </Label>
          <p>{selectedLeave.fromDate}</p>

          <Label>To Date: </Label>
          <p>{selectedLeave.toDate}</p>

          {selectedLeave.noOfDays ? (
            <>
              <Label>No. of Days: </Label>
              <p>{selectedLeave.noOfDays}</p>
            </>
          ) : (
            <>
              <Label>Hours: </Label>
              <p>{selectedLeave.hours}</p>
            </>
          )}

          <Label>Status: </Label>
          <p>{selectedLeave.status}</p>
        </ModalBody>
        <ModalFooter>
          <Button color="primary" onClick={() => toggleModal()}>
            OK
          </Button>
        </ModalFooter>
      </Modal>
    );
  };
  const filteredvacations = vacationData.filter((vacation) =>                                                 
    vacation.empName.toLowerCase().includes(vacationSearch.toLowerCase()) ||
    vacation.empNo.toLowerCase().includes(vacationSearch.toLowerCase()) ||
    vacation.transactionType.toLowerCase().includes(vacationSearch.toLowerCase())||
    vacation.fromDate.toLowerCase().includes(vacationSearch.toLowerCase()) ||
    vacation.toDate.toLowerCase().includes(vacationSearch.toLowerCase()) ||
    vacation.resumptionDate.toLowerCase().includes(vacationSearch.toLowerCase()) ||
    vacation.receivedOn.toLowerCase().includes(vacationSearch.toLowerCase()) ||
    vacation.noOfDays.toLowerCase().includes(vacationSearch.toLowerCase()) ||
    vacation.status.toLowerCase().includes(vacationSearch.toLowerCase()) 
  );
  const filteredhourlyLeave = hourlyLeaveData.filter((hourlyLeave) =>                                                 
  hourlyLeave.empName.toLowerCase().includes(hourlyLeaveSearch.toLowerCase()) ||
  hourlyLeave.empNo.toLowerCase().includes(hourlyLeaveSearch.toLowerCase()) ||
  hourlyLeave.transactionType.toLowerCase().includes(hourlyLeaveSearch.toLowerCase())||
  hourlyLeave.fromDate.toLowerCase().includes(hourlyLeaveSearch.toLowerCase()) ||
  hourlyLeave.toDate.toLowerCase().includes(hourlyLeaveSearch.toLowerCase()) ||
  hourlyLeave.resumptionDate.toLowerCase().includes(hourlyLeaveSearch.toLowerCase()) ||
  hourlyLeave.receivedOn.toLowerCase().includes(hourlyLeaveSearch.toLowerCase()) ||
  hourlyLeave.hours.toLowerCase().includes(hourlyLeaveSearch.toLowerCase()) ||
  hourlyLeave.status.toLowerCase().includes(hourlyLeaveSearch.toLowerCase()) 
  );
  const filteredadjustments = adjustmentsData.filter((adjustment) =>                                                 
    adjustment.empName.toLowerCase().includes(adjustmentSearch.toLowerCase()) ||
  adjustment.empNo.toLowerCase().includes(adjustmentSearch.toLowerCase()) ||
  adjustment.adjustmentName.toLowerCase().includes(adjustmentSearch.toLowerCase())||
  adjustment.amount.toLowerCase().includes(adjustmentSearch.toLowerCase()) ||
  adjustment.effectiveDate.toLowerCase().includes(adjustmentSearch.toLowerCase()) 
  
    );
    const filteredbalances = MovedData.filter((moved) =>                                                 
      moved.empName.toLowerCase().includes(movedSearch.toLowerCase()) ||
    moved.empNo.toLowerCase().includes(movedSearch.toLowerCase()) ||
    moved.vacationName.toLowerCase().includes(movedSearch.toLowerCase())||
    moved.amount.toLowerCase().includes(movedSearch.toLowerCase()) ||
    moved.effectiveDate.toLowerCase().includes(movedSearch.toLowerCase()) 
    
      );
  return (
    <div className="container-fluid">
      {/* Breadcrumb and Page Title */}
      <div style={{ height: '30px' }} className="container-fluid">
        <div className="page-title" style={{ display: 'none' }}>
          <div className="row">
            <div className="col-6">
              <h3></h3>
            </div>
            <div className="col-6">
              <ol className="breadcrumb">
                <li className="breadcrumb-item">
                  <a href="/dashboard">Dashboard</a>
                </li>
                <li className="breadcrumb-item active">Clients</li>
              </ol>
            </div>
          </div>
        </div>
      </div>

      {/* Tabs for switching between Vacation Days, Hourly Leaves, and Manage Adjustments */}
      <Nav tabs>
        <NavItem>
          <NavLink
            className={classnames({ active: activeTab === '1' })}
            onClick={() => toggleTab('1')}
            style={{
              cursor: 'pointer', 
              color: activeTab === '1' ? '#194995' : 'black',  // Apply #194995 color when active
            }}
          >
            Vacation Days
          </NavLink>
        </NavItem>
        <NavItem>
          <NavLink
            className={classnames({ active: activeTab === '2' })}
            onClick={() => toggleTab('2')}
            style={{
              cursor: 'pointer', 
              color: activeTab === '2' ? '#194995' : 'black',  // Apply #194995 color when active
            }}
          >
            Hourly Leaves
          </NavLink>
        </NavItem>
        <NavItem>
          <NavLink
            className={classnames({ active: activeTab === '3' })}
            onClick={() => toggleTab('3')}
            style={{
              cursor: 'pointer', 
              color: activeTab === '3' ? '#194995' : 'black',  // Apply #194995 color when active
            }}
          >
            Manage Adjustments
          </NavLink>
        </NavItem>
        <NavItem>
          <NavLink
            className={classnames({ active: activeTab === '4' })}
            onClick={() => toggleTab('4')}
            style={{
              cursor: 'pointer', 
              color: activeTab === '4' ? '#194995' : 'black',  // Apply #194995 color when active
            }}
          >
            Manage Moved Balances
          </NavLink>
        </NavItem>
      </Nav>

      <TabContent activeTab={activeTab}>
        {/* Vacation Days Tab */}
        {/* Vacation Days Tab */}
<TabPane tabId="1">
  <Card>
    <CardHeader>
      <h3 style={{ fontSize: '24px', fontWeight: 'bold', color: '#194995', marginBottom: '10px' }}>Vacation Days</h3>
      <Input
        type="text"
        placeholder="Search Vacation Days"
        value={vacationSearch}
        onChange={(e) => setvacationSearch(e.target.value)}
        style={{ width: '60%' }} // Adjust width to make it responsive
      />
    </CardHeader>
    <CardBody>
      <Table bordered hover>
        <thead>
          <tr>
            <th>Emp. Name</th>
            <th>Emp. No.</th>
            <th>Transaction Type</th>
            <th>From Date</th>
            <th>To Date</th>
            <th>Resumption Date</th>
            <th>Received On</th>
            <th>No. of Days</th>
            <th>Status</th>
            <th>Actions</th>
          </tr>
        </thead>
        <tbody>
          {filteredvacations.map((vacation) => (
            <tr key={vacation.id}>
              <td>{vacation.empName}</td>
              <td>{vacation.empNo}</td>
              <td>{vacation.transactionType}</td>
              <td>{vacation.fromDate}</td>
              <td>{vacation.toDate}</td>
              <td>{vacation.resumptionDate}</td>
              <td>{vacation.receivedOn}</td>
              <td>{vacation.noOfDays}</td>
              <td>{vacation.status}</td>
              <td>
                <Button color="primary" onClick={() => toggleModal(vacation)}>
                  View
                </Button>
              </td>
            </tr>
          ))}
        </tbody>
      </Table>
    </CardBody>
  </Card>
</TabPane>

{/* Hourly Leaves Tab */}
<TabPane tabId="2">
  <Card>
    <CardHeader>
      <h3 style={{ fontSize: '24px', fontWeight: 'bold', color: '#194995', marginBottom: '10px' }}>Hourly Leaves</h3>
      <Input
        type="text"
        placeholder="Search Hourly Leaves"
        value={hourlyLeaveSearch}
        onChange={(e) => sethourlyLeaveSearch(e.target.value)}
        style={{ width: '60%' }} // Adjust width to make it responsive
      />
    </CardHeader>
    <CardBody>
      <Table bordered hover>
        <thead>
          <tr>
            <th>Emp. Name</th>
            <th>Emp. No.</th>
            <th>Transaction Type</th>
            <th>From Date</th>
            <th>To Date</th>
            <th>Hours</th>
            <th>Status</th>
            <th>Actions</th>
          </tr>
        </thead>
        <tbody>
          {filteredhourlyLeave.map((leave) => (
            <tr key={leave.id}>
              <td>{leave.empName}</td>
              <td>{leave.empNo}</td>
              <td>{leave.transactionType}</td>
              <td>{leave.fromDate}</td>
              <td>{leave.toDate}</td>
              <td>{leave.hours}</td>
              <td>{leave.status}</td>
              <td>
                <Button color="primary" onClick={() => toggleModal(leave)}>
                  View
                </Button>
              </td>
            </tr>
          ))}
        </tbody>
      </Table>
    </CardBody>
  </Card>
</TabPane>

{/* Manage Adjustments Tab */}
<TabPane tabId="3">
  <Card>
    <CardHeader>
      <h3 style={{ fontSize: '24px', fontWeight: 'bold', color: '#194995', marginBottom: '10px' }}>Manage Adjustments</h3>
      <Input
        type="text"
        placeholder="Search Manage Adjustments"
        value={adjustmentSearch}
        onChange={(e) => setadjustmentSearch(e.target.value)}
        style={{ width: '60%' }} // Adjust width to make it responsive
      />
    </CardHeader>
    <CardBody>
      <Table bordered hover>
        <thead>
          <tr>
            <th>Emp. Name</th>
            <th>Emp. No.</th>
            <th>Adjustment Name</th>
            <th>Amount</th>
            <th>Effective Date</th>
            <th>Actions</th>
          </tr>
        </thead>
        <tbody>
          {filteredadjustments.map((adjustment) => (
            <tr key={adjustment.id}>
              <td>{adjustment.empName}</td>
              <td>{adjustment.empNo}</td>
              <td>{adjustment.adjustmentName}</td>
              <td>{adjustment.amount}</td>
              <td>{adjustment.effectiveDate}</td>
              <td>
                <Button color="primary" onClick={() => toggleModal(adjustment)}>
                  View
                </Button>
              </td>
            </tr>
          ))}
        </tbody>
      </Table>
    </CardBody>
  </Card>
</TabPane>

{/* Manage Moved Balances Tab */}
<TabPane tabId="4">
  <Card>
    <CardHeader>
      <h3 style={{ fontSize: '24px', fontWeight: 'bold', color: '#194995', marginBottom: '10px' }}>Manage Moved Balances</h3>
      <Input
        type="text"
        placeholder="Search Manage Moved Balances"
        value={movedSearch}
        onChange={(e) => setmovedSearch(e.target.value)}
        style={{ width: '60%' }} // Adjust width to make it responsive
      />
    </CardHeader>
    <CardBody>
      <Table bordered hover>
        <thead>
          <tr>
            <th>Emp. Name</th>
            <th>Emp. No.</th>
            <th>Leave/Vacation Name</th>
            <th>Amount</th>
            <th>Effective Date</th>
          </tr>
        </thead>
        <tbody>
          {filteredbalances.map((mBalances) => (
            <tr key={mBalances.id}>
              <td>{mBalances.empName}</td>
              <td>{mBalances.empNo}</td>
              <td>{mBalances.vacationName}</td>
              <td>{mBalances.amount}</td>
              <td>{mBalances.effectiveDate}</td>
            </tr>
          ))}
        </tbody>
      </Table>
    </CardBody>
  </Card>
</TabPane>

 </TabContent>

      {renderModal()}
    </div>
  );
};

export default AdminTimeManagment;
