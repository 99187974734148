import React, { useState, useEffect } from 'react';
import { toast } from 'react-toastify';
import {
    Table,
    Button,
    Input,
    Modal,
    ModalHeader,
    ModalBody,
    ModalFooter,
    Card,
    CardBody,
    CardHeader,
    Form,
    FormGroup,
    Label,
} from 'reactstrap';
import { fetchtasks, createtasks, updatetasks, deletetasks } from 'Services/managetasks.service';
import { getUserOptions } from 'Services/user.service';

const ManageTasks = () => {
    const [tasksModal, settasksModal] = useState(false);
    const [tasksSearch, settasksSearch] = useState('');
    const [tasks, settasks] = useState([]);
    const [userOptions, setUserOptions] = useState([]); // Store user options
    const [tasksformvalues, settasksformvalues] = useState([{
        emp_name: '',
        emp_no: '',
        createdAt: '',
        task_type: '',
        status: '',
        notes: '',
    }]);
    const [istasksEditMode, setIstasksEditMode] = useState(false);
    // Load tasks
    const loadtasksData = async () => {
        try {
            const fetchedtasks = await fetchtasks();
            if (fetchedtasks && Array.isArray(fetchedtasks.rows)) {
                settasks(fetchedtasks.rows);
            } else {
                console.error('Expected an array in rows, but got:', fetchedtasks);
            }
        } catch (error) {
            console.error('Error fetching and filtering tasks:', error);
        }
    };

    // Load user options
    const loadUserOptions = async () => {
        try {
            // Step 1: Fetch user options
            const response = await getUserOptions();
            console.log('API Response:', response);

            // Step 2: Check if response is valid
            if (!response || !response.data || !Array.isArray(response.data.data)) {
                console.error('No valid data in response:', response);
                return; // Exit if response or data is invalid
            }

            // Step 3: Access the actual user data array
            const userDataArray = response.data.data; // Access the nested 'data' array
            console.log('User Data Array:', userDataArray);

            // Step 4: Proceed with formatting options
            const formattedOptions = userDataArray.map(user => {
                // Check for required properties
                if (user.id && user.firstName && user.lastName) {
                    return {
                        value: user.id,
                        label: `${user.firstName} ${user.lastName}`
                    };
                } else {
                    console.error('User object is missing properties:', user);
                    return null; // Handle missing properties
                }
            }).filter(option => option !== null); // Remove nulls if any

            console.log('Formatted Options:', formattedOptions);
            setUserOptions(formattedOptions); // Set formatted options
        } catch (error) {
            console.error('Failed to fetch employees:', error);
        }
    };

    useEffect(() => {
        loadtasksData();
        loadUserOptions(); // Fetch users on component load
    }, []);

    const toggleTaskModal = () => settasksModal(!tasksModal);




    const handletasksFormChange = (e, id) => {
        const { name, value } = e.target;
        const updatedTasks = [...tasksformvalues];

        if (name === 'emp_name') {
            const selectedEmployee = userOptions.find(emp => `${emp.label}` === value);

            updatedTasks[id] = {
                ...updatedTasks[id],
                emp_name: value, // Set employee name
                emp_no: selectedEmployee ? selectedEmployee.value : '' // Auto-fill employee number
            };
        } else {
            updatedTasks[id] = { ...updatedTasks[id], [name]: value }; // Regular field update
        }

        settasksformvalues(updatedTasks);
    };



    const initializeCreateTaskForm = () => {
        setIstasksEditMode(false);
        settasksformvalues([{
            emp_name: '',
            emp_no: '',
            createdAt: '',
            task_type: '',
            status: '',
            notes: '',
        }]);
        toggleTaskModal();
    };

    const initializeUpdateTaskForm = (task) => {
        settasksformvalues([task]);
        setIstasksEditMode(true);
        toggleTaskModal();
    };

    const deleteTask = async (id) => {
        try {
            await deletetasks(id);
            toast.success('Task deleted successfully!');
            settasks((prevDetails) => prevDetails.filter((task) => task.id !== id));
        } catch (error) {
            console.error('Error deleting task:', error);
            toast.error('Failed to delete task. Please try again.');
        }
    };

    const handleSaveTask = async (e) => {
        e.preventDefault();
        try {
            if (istasksEditMode) {
                await updatetasks(tasksformvalues[0].id, tasksformvalues[0]);
                toast.success('Task updated successfully!');
            } else {
                await createtasks(tasksformvalues[0]);
                toast.success('Task added successfully!');
            }
            await loadtasksData();
            toggleTaskModal();
        } catch (error) {
            console.error('Error saving task:', error);
            toast.error('Failed to save task. Please try again.');
        }
    };

    const handleSearchtaskChange = (e) => {
        settasksSearch(e.target.value);
    };


    const filteredTasks = tasks.filter((task) =>
        task.emp_name.toLowerCase().includes(tasksSearch.toLowerCase()) ||
        task.emp_no.toLowerCase().includes(tasksSearch.toLowerCase())
    );







    const rendertaskModal = () => (
        <Modal isOpen={tasksModal} toggle={toggleTaskModal}>
            <ModalHeader toggle={toggleTaskModal}>
                {istasksEditMode ? 'Edit Task Info' : 'Add Task Info'}
            </ModalHeader>
            <ModalBody>
                <Form onSubmit={handleSaveTask}>
                    {tasksformvalues.map((task, index) => (
                        <div key={task.id || index}> {/* Use unique ID if available */}
                            {/* Employee Name - Dropdown */}
                            <FormGroup>
                                <Label for="emp_name">Employee Name</Label>
                                <Input
                                    type="select"
                                    name="emp_name"
                                    id="emp_name"
                                    value={tasksformvalues[0].emp_name}
                                    onChange={(e) => handletasksFormChange(e, 0)}
                                >
                                    <option value="">Select Employee</option>
                                    {userOptions.map(user => (
                                        <option key={user.value} value={user.label}>
                                            {user.label}
                                        </option>
                                    ))}
                                </Input>
                            </FormGroup>
                            <FormGroup>
                                <Label for="emp_no">Employee Number</Label>
                                <Input
                                    type="text"
                                    name="emp_no"
                                    id="emp_no"
                                    value={tasksformvalues[0].emp_no}
                                    onChange={(e) => handletasksFormChange(e, 0)}
                                    disabled // Disable this field as it auto-fills based on employee selection
                                />
                            </FormGroup>


                            {/* Assigned At */}
                            <FormGroup>
                                <Label for={`createdAt_${index}`}>Assigned At</Label>
                                <Input
                                    type="date"
                                    name="createdAt"
                                    id={`createdAt_${index}`}
                                    value={task.createdAt || ''}
                                    onChange={(e) => handletasksFormChange(e, index)}
                                />
                            </FormGroup>

                            {/* Task Type */}
                            <FormGroup>
                                <Label for={`task_type_${index}`}>Task Type</Label>
                                <Input
                                    type="select"
                                    name="task_type"
                                    id={`task_type_${index}`}
                                    value={task.task_type || ''}
                                    onChange={(e) => handletasksFormChange(e, index)}
                                    required
                                >
                                    <option value="">Select Task</option>
                                    <option value="Upload Document">Upload Document</option>
                                    <option value="Download Document">Download Document</option>
                                    <option value="Give Asset">Give Asset</option>
                                    <option value="Take Asset">Take Asset</option>
                                    <option value="Bank Account">Bank Account</option>
                                    <option value="Add Family">Add Family</option>
                                    <option value="Customized Task">Customized Task</option>
                                </Input>
                            </FormGroup>

                            {/* Status */}
                            <FormGroup>
                                <Label for={`status_${index}`}>Status</Label>
                                <Input
                                    type="select"
                                    name="status"
                                    id={`status_${index}`}
                                    value={task.status || 'Pending'}
                                    onChange={(e) => handletasksFormChange(e, index)}
                                    required
                                >
                                    <option value="Pending">Pending</option>
                                    <option value="In_Progress">In Progress</option>
                                    <option value="Completed">Completed</option>
                                    <option value="Canceled">Canceled</option>
                                </Input>
                            </FormGroup>

                            {/* Description */}
                            <FormGroup>
                                <Label for={`notes_${index}`}>Description</Label>
                                <Input
                                    type="textarea"
                                    name="notes"
                                    id={`notes_${index}`}
                                    value={task.notes || ''}
                                    onChange={(e) => handletasksFormChange(e, index)}
                                />
                            </FormGroup>
                        </div>
                    ))}
                    <ModalFooter>
                        <Button color="primary" type="submit">
                            {istasksEditMode ? 'Update' : 'Save'}
                        </Button>
                        <Button color="secondary" onClick={toggleTaskModal}>
                            Cancel
                        </Button>
                    </ModalFooter>
                </Form>
            </ModalBody>
        </Modal>
    );
    return (
        <>
            <Card>
                <CardHeader>
                    <h3 style={{ fontSize: '24px', fontWeight: 'bold', color: '#194995', marginBottom: '20px' }}>
                        Manage Tasks
                    </h3>
                    <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
                        <Input
                            type="text"
                            placeholder="Search by Employee Name or Number"
                            value={tasksSearch}
                            onChange={handleSearchtaskChange}
                            style={{ width: '300px', marginBottom: '20px' }}
                        />
                        <Button color="primary" onClick={initializeCreateTaskForm}>
                            Add Task
                        </Button>
                    </div>
                </CardHeader>
                <CardBody>
                    <Table striped>
                        <thead>
                            <tr>
                                <th>Employee Name</th>
                                <th>Employee Number</th>
                                <th>Task Type</th>
                                <th>Assigned At</th>
                                <th>Status</th>
                                <th>Edit</th>
                                <th>Delete</th>
                            </tr>
                        </thead>
                        <tbody>
                            {filteredTasks.length > 0 ? (
                                filteredTasks.map((task) => (
                                    <tr key={task.id}>
                                        <td>{task.emp_name}</td>
                                        <td>{task.emp_no}</td>
                                        <td>{task.task_type}</td>
                                        <td>{task.createdAt}</td>
                                        <td>{task.status}</td>
                                        <td>
                                            <Button color="primary" onClick={() => initializeUpdateTaskForm(task)}>
                                                Edit
                                            </Button>
                                        </td>
                                        <td>
                                            <Button color="danger" onClick={() => deleteTask(task.id)}>
                                                Delete
                                            </Button>
                                        </td>
                                    </tr>
                                ))
                            ) : (
                                <tr>
                                    <td colSpan="7" className="text-center">No tasks found</td>
                                </tr>
                            )}
                        </tbody>
                    </Table>
                </CardBody>
            </Card>
            {rendertaskModal()}
        </>
    );
};

export default ManageTasks;
