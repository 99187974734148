import api from './api';

export const TradingGroup = () => {
    return api.get('/v1/crm/TradingGroup/');
};

export const createTradingGroup = (data) => {
  return api.post('/v1/crm/TradingGroup/', data);
};

export const deleteTradingGroup = (id) => {
  return api.delete(`/v1/crm/TradingGroup/${id}`);
};

export const updateTradingGroup = (id, data) => {
  return api.put(`/v1/crm/TradingGroup/${id}`, data);
};


