import api from './api';

export const UsersPersonalDetail = () => {
    return api.get('/v1/crm/UsersPersonalDetail');
};

export const createUsersPersonalDetail = (data) => {
    return api.post('/v1/crm/UsersPersonalDetail', data);
};

export const updateUsersPersonalDetail = (id, data) => {
    return api.put(`/v1/crm/UsersPersonalDetail/${id}`, data);
};

export const deleteUsersPersonalDetail = (id) => {
    return api.delete(`/v1/crm/UsersPersonalDetail/${id}`);
};

export const addImage = (id,data) => {
    console.log(data, 'file')
    return api.put(`/v1/crm/UsersPersonalDetail/image/${id}`,data);
};