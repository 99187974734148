import api from './api';

export const fetchUsersAssets = () => {
    return api.get('./v1/crm/UsersAssets');
};

export const createUsersAssets = (data) => {
    return api.post('./v1/crm/UsersAssets', data);
};

export const updateUsersAssets= (id, data) => {
    return api.put(`./v1/crm/UsersAssets/${id}`, data);
};

export const deleteUsersAssets = (id) => {
    return api.delete(`/v1/crm/UsersAssets/${id}`);
};
    