import api from './api';

export const fetchloanreq = () => {
    return api.get(`/v1/crm/loanreq`);
};

export const createloanreq = (data) => {
    return api.post(`/v1/crm/loanreq`, data);
};

export const updateloanreq= (id, data) => {
    return api.put(`/v1/crm/loanreq/${id}`, data);
};

export const deleteloanreq = (id) => {
    return api.delete(`/v1/crm/loanreq/${id}`);
};
    